@import "./../utils/values.scss";
@import "./../utils/mixins.scss";
section.interests {
  @include d-flex-column;
  align-items: center;
  gap: $baseGutter * 2;
  padding: ($baseGutter * 8) ($baseGutter * 16) ($baseGutter * 12);
  background: url(./../../images/interests-bg.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
  }
  h1.title {
    position: relative;
    z-index: 9;
    color: black;
    text-shadow: 0 0 2px white;
  }
  div.content {
    position: relative;
    display: grid;
    gap: $baseGutter;
    padding: ($baseGutter * 2);
    z-index: 9;
    div.card {
      background: linear-gradient(
        to bottom,
        rgba(205, 205, 230, 0.8),
        rgba(206, 197, 234, 0.5)
      );
      backdrop-filter: blur(8px);
      letter-spacing: 0.7px;
      @include d-flex-row;
      gap: $baseGutter * 2;
      border-radius: $baseGutter;
      border: 0.5px solid rgba(255, 255, 255, 0.25);
      box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.15);
      div.image {
        position: relative;
        img {
          height: 100%;
          width: 200px;
          object-fit: cover;
        }
        h2 {
          color: white;
          position: absolute;
          bottom: 0;
          left: 0;
          background: linear-gradient(
            to top,
            rgba(0, 0, 0, 1),
            rgba(255, 255, 255, 0)
          );
          font-weight: 500;
          width: 100%;
          text-align: center;
          text-shadow: 0 0 1px white;
        }
      }
      p {
        font-size: $mText;
        text-align: justify;
        padding: $baseGutter * 2;
        align-self: center;
      }
      &:nth-of-type(1) {
        grid-column: 1/3;
        grid-row: 1/2;
        div.image {
          img {
            border-top-left-radius: $baseGutter;
            border-bottom-left-radius: $baseGutter;
          }
          h2 {
            border-bottom-left-radius: $baseGutter;
          }
        }
      }
      &:nth-of-type(2) {
        grid-column: 3/5;
        grid-row: 1/2;
        flex-direction: column;
        div.image {
          img {
            height: 200px;
            width: 100%;
            min-width: 240px;
            border-top-left-radius: $baseGutter;
            border-top-right-radius: $baseGutter;
          }
        }
      }
      &:nth-of-type(3) {
        grid-column: 5/7;
        grid-row: 1/2;
        flex-direction: row-reverse;
        div.image {
          img {
            border-top-right-radius: $baseGutter;
            border-bottom-right-radius: $baseGutter;
          }
          h2 {
            border-bottom-right-radius: $baseGutter;
          }
        }
      }
      &:nth-of-type(4) {
        grid-column: 1/4;
        grid-row: 2/3;
        flex-direction: row-reverse;
        div.image {
          img {
            border-top-right-radius: $baseGutter;
            border-bottom-right-radius: $baseGutter;
          }
          h2 {
            border-bottom-right-radius: $baseGutter;
          }
        }
      }
      &:nth-of-type(5) {
        grid-column: 4/7;
        grid-row: 2/3;
        div.image {
          img {
            border-top-left-radius: $baseGutter;
            border-bottom-left-radius: $baseGutter;
          }
          h2 {
            border-bottom-left-radius: $baseGutter;
          }
        }
      }
    }
  }
}
