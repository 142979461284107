@import "./../utils/values.scss";
@import "./../utils/mixins.scss";
section.participation {
  background: $participationBgColor;
  padding: ($baseGutter * 10) ($baseGutter * 4) ($baseGutter * 16);
  position: relative;
  @include d-flex-column;
  align-items: center;
  gap: $baseGutter * 6;
  h1.title {
    position: relative;
    z-index: 9;
  }
  div.content {
    @include d-flex-row;
    gap: $baseGutter * 2;
    position: relative;
    z-index: 9;
    div.card {
      border: dashed 2px $textTitleColor;
      border-radius: $baseGutter;
      padding: $baseGutter * 2;
      @include d-flex-column;
      align-items: center;
      img {
        max-width: 8rem;
      }
      div.workshop-logos {
        @include d-flex-row;
        align-items: center;
        img {
          max-width: 4rem;
        }
      }
      h2 {
        margin-top: $baseGutter * 2;
        font-weight: 500;
        text-shadow: 0 0 2px white;
      }
      p {
        font-size: $mText;
        text-align: justify;
        max-width: 24rem;
      }
    }
  }
  div.tooth-icon {
    position: absolute;
    top: -2px;
    left: 50%;
    background: rgba(47, 79, 79, 0.8);
    backdrop-filter: blur(4px);
    transform: translate(-50%, -50%);
    color: white;
    padding: $baseGutter;
    height: 60px;
    width: 60px;
    border-radius: 25%;
    @include d-flex-column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 8px 8px 8px rgba(0, 0, 0, 0.5),
      inset -8px -8px 8px rgba(255, 255, 255, 0.5);
    z-index: 9;
    svg {
      width: 50px;
      height: 50px;
      filter: drop-shadow(0 0 4px rgba(255, 217, 0, 0.5));
    }
  }
  &:after {
    content: " ";
    position: absolute;
    right: $baseGutter * 2;
    left: calc($baseGutter * 3 + 31px);
    top: -2px;
    bottom: $baseGutter * 6;
    border-right: solid 2px rgba(47, 79, 79, 0.5);
    border-bottom: solid 2px rgba(47, 79, 79, 0.5);
    box-sizing: border-box;
  }
  div.alt-icon {
    position: absolute;
    left: $baseGutter * 2;
    bottom: $baseGutter * 7;
    background: rgba(47, 79, 79, 0.8);
    backdrop-filter: blur(4px);
    height: 60px;
    width: 60px;
    z-index: 9;
    @include d-flex-column;
    justify-content: center;
    align-items: center;
    border-radius: 25%;
    padding: $baseGutter;
    box-shadow: inset 8px 8px 8px rgba(0, 0, 0, 0.5),
      inset -8px -8px 8px rgba(255, 255, 255, 0.5);
    svg {
      margin-top: 4px;
      color: $participationBgColor;
      height: 46px;
      width: 46px;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.75));
    }
    &:after {
      content: " ";
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(50%);
      bottom: -$baseGutter;
      border: 1px solid rgba(47, 79, 79, 0.5);
    }
  }
}
@media only screen and (max-width: $mediumScreenBreakpoint) {
  section.participation {
    div.content {
      flex-direction: column;
    }
  }
}
@media only screen and (max-width: $smallScreenBreakpoint) {
  section.participation {
    div.content {
      div.card {
        h2 {
          margin-top: $baseGutter * 2;

          text-shadow: 0 0 1px white;
          font-size: $mText;
        }
        p {
          font-size: $sText;
        }
      }
    }
  }
}
