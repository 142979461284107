@import "./../utils/values.scss";
@import "./../utils/mixins.scss";
$thumbnailDimension: 100px;
section.credits {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: linear-gradient(
    rgba(108, 101, 165, 0.5),
    rgba(226, 226, 234, 0.5)
  );
  backdrop-filter: blur(4px);
  @include d-flex-column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    rgba(217, 222, 225, 0.5),
    rgba(150, 210, 230, 0.25)
  );
  backdrop-filter: blur(4px);
  color: $textParagraphColor;
  div.wrapper {
    position: relative;
    height: 80%;
    width: 60%;
    border-radius: 6px;
    box-shadow: 4px 4px 16px 4px #f1f1f120;
    border: 0.5px solid #f1f1f15d;
    background: linear-gradient(
      rgba(255, 255, 255, 0.95),
      rgba(200, 220, 255, 0.9)
    );
    backdrop-filter: blur(64px);
    button.close {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      outline: transparent;
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      cursor: pointer;
      color: white;
      background: rgb(53, 53, 90);
      box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.15);
      z-index: 99;
      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 30px;
        height: 30px;
        transform: translate(-50%, -50%);
      }
    }
    div.content {
      overflow: auto;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 32px 64px;
      @include d-flex-column;
      gap: $baseGutter * 4;
      div.item {
        @include d-flex-column;
        margin: $baseGutter 0;
        h3 {
          color: $textTitleColor;
        }
        h4 {
          color: $textParagraphColor;
        }
        a {
          text-decoration: none;
          text-align: left;
        }
        & > div {
          @include d-flex-row;
          gap: $baseGutter * 2;
          align-items: center;
          img {
            width: $thumbnailDimension;
            height: $thumbnailDimension;
            border-radius: 4px;
            box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.15);
          }
          & > div {
            @include d-flex-column;
            align-items: flex-start;
            gap: 0;
            & > div,
            a {
              @include d-flex-row;
              gap: $baseGutter;
              align-items: center;
            }
          }
        }
        hr {
          border-top: 1px solid rgba(60, 32, 95, 0.75);
          width: calc(100% - $thumbnailDimension - ($baseGutter * 2));
          @include no-margin-padding;
          margin: $baseGutter 0;
          align-self: flex-end;
        }
      }
      &::-webkit-scrollbar {
        background: transparent;
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: darkslategray;
        background: rgba(47, 79, 79, 0.3);
        border-radius: 25px;
      }
    }
  }
}
