@import "./../utils/values.scss";
@import "./../utils/mixins.scss";
$iconSize: 4rem;
section.services {
  position: relative;
  @include d-flex-column;
  align-items: center;
  gap: $baseGutter * 4;
  padding: ($baseGutter * 10) ($baseGutter * 4) ($baseGutter * 12);
  background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url(./../../images/services-bg.jpg);
  background-size: cover;
  background-position: center;
  div.blur-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(4px);
  }
  h1.title {
    position: relative;
  }
  div.content {
    height: 60vh;
    width: 80%;
    position: relative;
    div.item {
      position: absolute;
      @include d-flex-column;
      align-items: center;
      height: fit-content;
      width: fit-content;
      h2 {
        font-weight: 500;
        letter-spacing: 1px;
        max-width: 160px;
        text-align: center;
      }
      svg {
        color: yellow;
        height: 60px;
        width: 60px;
        filter: drop-shadow(0 0 1px black);
      }
      &:nth-of-type(1) {
        left: 33.33%;
        top: 20%;
        transform: translate(-50%, -50%);
      }
      &:nth-of-type(2) {
        right: 33.33%;
        top: 20%;
        transform: translate(50%, -50%);
        svg {
          color: gold;
        }
      }
      &:nth-of-type(3) {
        right: 12%;
        top: 50%;
        transform: translate(50%, -50%);
        svg {
          color: snow;
        }
      }
      &:nth-of-type(4) {
        right: 33.33%;
        bottom: 20%;
        transform: translate(50%, 50%);
        svg {
          color: silver;
        }
      }
      &:nth-of-type(5) {
        left: 33.33%;
        bottom: 20%;
        transform: translate(-50%, 50%);
        svg {
          color: orchid;
        }
      }
      &:nth-of-type(6) {
        left: 12%;
        top: 50%;
        transform: translate(-50%, -50%);
        svg {
          color: lavender;
        }
      }
    }
    hr {
      position: absolute;
      background: silver;
      border: solid 2px silver;
      width: 10%;
      &:nth-of-type(1) {
        left: 50%;
        top: 20%;
        transform: translate(-50%, -50%);
      }
      &:nth-of-type(2) {
        right: 22.665%;
        top: 35%;
        transform: translate(50%, -50%) rotate(45deg);
      }
      &:nth-of-type(3) {
        right: 22.665%;
        bottom: 35%;
        transform: translate(50%, 50%) rotate(-45deg);
      }
      &:nth-of-type(4) {
        left: 50%;
        bottom: 20%;
        transform: translate(-50%, 50%);
      }
      &:nth-of-type(5) {
        left: 22.665%;
        bottom: 35%;
        transform: translate(-50%, 50%) rotate(45deg);
      }
      &:nth-of-type(6) {
        left: 22.665%;
        top: 35%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
@media only screen and (max-width: ($mediumScreenBreakpoint + 200px)) {
  section.services {
    padding-left: $baseGutter * 2;
    padding-right: $baseGutter * 2;
    div.content {
      height: 40vw;
      width: 80%;
      div.item {
        h2 {
          font-size: $sText;
          letter-spacing: 0.5px;
          max-width: 140px;
        }
        svg {
          height: 50px;
          width: 50px;
        }
      }
      hr {
        border-width: 1.6px;
        width: 8%;
      }
    }
  }
}
@media only screen and (max-width: $mediumScreenBreakpoint) {
  section.services {
    padding-left: 0;
    padding-right: 0;
    div.content {
      height: 45vw;
      width: 90%;
    }
  }
}
@media only screen and (max-width: ($mediumScreenBreakpoint - 200px)) {
  section.services {
    div.content {
      height: 50vw;
      width: 100%;
      div.item {
        h2 {
          font-size: $xsText;
          max-width: 100px;
        }
        svg {
          height: 40px;
          width: 40px;
          filter: drop-shadow(0 0 0.8px black);
        }
      }
      hr {
        width: 8%;
      }
    }
  }
}
@media only screen and (max-width: ($smallScreenBreakpoint + 100px)) {
  section.services {
    div.content {
      height: 60vw;
      div.item {
        h2 {
          font-size: $xxsText;
          letter-spacing: 0.2px;
          max-width: 80px;
        }
        svg {
          height: 30px;
          width: 30px;
          filter: drop-shadow(0 0 0.8px black);
        }
      }
      hr {
        border-width: 1px;
        width: 6%;
      }
    }
  }
}
