// MARGIN PADDING
@mixin no-margin-padding {
  margin: 0;
  padding: 0;
}

@mixin section-padding {
  padding: $baseGutter;
}

// DISPLAY
@mixin d-flex-column {
  display: flex;
  flex-direction: column;
}

@mixin d-flex-row {
  display: flex;
  flex-direction: row;
}

@mixin title-text {
  font-family: "Italianno", cursive;
  font-size: $xxlText;
  letter-spacing: 2px;
}
