// COLORS
$heroBgColor: #e3cdcd;
// $experienceBgColor: #fffafa; // snow
$participationBgColor: rgb(207, 207, 241);
$textTitleColor: #2f4f4f;
$textParagraphColor: #1c1c1c;

// SPACING
$baseGutter: 8px;

// FONT SIZE
$xxsText: 0.8rem;
$xsText: 1rem;
$sText: 1.2rem;
$mText: 1.4rem;
$lText: 1.8rem;
$xlText: 2.4rem;
$xxlText: 3rem;

// SCREEN BREAKPOINTS
$mediumScreenBreakpoint: 1000px;
$smallScreenBreakpoint: 500px;
