@import "./../utils/values.scss";
@import "./../utils/mixins.scss";
$iconColor: #bbddee;
section.footer {
  position: relative;
  background: linear-gradient(to bottom, darkslategray 5%, black 100%);
  @include d-flex-column;
  align-items: center;
  text-align: center;
  color: white;
  letter-spacing: 0.7px;
  padding: ($baseGutter * 8) ($baseGutter);
  gap: $baseGutter * 4;
  h3 {
    color: white;
    font-weight: 500;
    font-size: 1.3rem;
  }
  p {
    font-size: 1.2rem;
  }
  div.social-media {
    @include d-flex-row;
    align-items: center;
    gap: $baseGutter;
    svg {
      height: 40px;
      width: 40px;
      color: $iconColor;
    }
  }
  div.enquiry {
    @include d-flex-column;
    align-items: center;
    div.email {
      @include d-flex-row;
      align-items: center;
      gap: calc($baseGutter/2);
      svg {
        height: 24px;
        color: $iconColor;
      }
    }
  }
  div.credits {
    button {
      border: none;
      color: $iconColor;
      text-shadow: 0 0 4px black;
      outline: transparent;
      cursor: pointer;
      background: transparent;
      font-family: inherit;
      font-size: $xsText;
      text-transform: capitalize;
      letter-spacing: 0.7px;
      font-weight: 400;
      border-bottom: 1px solid $iconColor;
      padding: 0;
    }
  }
  svg.curve {
    height: 40px;
    position: absolute;
    bottom: 99%;
    left: 0;
    path {
      fill: darkslategray;
    }
  }
}
@media only screen and (max-width: $mediumScreenBreakpoint) {
  section.footer svg.curve {
    bottom: 98%;
  }
}
@media only screen and (max-width: $smallScreenBreakpoint) {
  section.footer {
    h3 {
      font-weight: 400;
      font-size: $sText;
    }
    p {
      font-size: $xsText;
    }
    div.social-media {
      gap: calc($baseGutter/2);
      svg {
        height: 30px;
        width: 30px;
      }
    }
    div.enquiry {
      div.email {
        svg {
          height: 18px;
        }
      }
    }
    div.credits {
      button {
        font-size: $xxsText;
      }
    }
    svg.curve {
      bottom: 97%;
    }
  }
}
