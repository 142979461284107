@import "./../utils/values.scss";
@import "./../utils/mixins.scss";
$accentColor: #c1e8f6;
section.testimonials {
  padding: ($baseGutter * 8) ($baseGutter * 4) ($baseGutter * 20);
  @include d-flex-column;
  align-items: center;
  gap: $baseGutter * 12;
  background: linear-gradient(to bottom, white, $accentColor);
  h1.title {
    position: relative;
  }
  div.content {
    @include d-flex-row;
    justify-content: space-evenly;
    width: 100%;
    position: relative;
    gap: $baseGutter * 2;
    div.card {
      box-shadow: 0 0 64px 16px rgba(0, 0, 0, 0.1);
      width: 33.3333%;
      background: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(4px);
      border-radius: 8px;
      border: solid 0.5px rgba(255, 255, 255, 0.5);
      padding: ($baseGutter * 4) 0;
      letter-spacing: 0.5px;
      position: relative;
      margin-right: 20px;
      margin-top: 20px;
      @include d-flex-column;
      justify-content: flex-start;
      max-width: 400px;
      hr {
        border: none;
        border-top: solid 1px $accentColor;
        width: 100%;
        margin: ($baseGutter * 1.5) 0;
      }
      h3 {
        font-size: $mText;
        font-weight: 600;
        margin: 0 $baseGutter * 2;
      }
      h4 {
        font-size: $sText;
        color: royalblue;
        margin: 0 $baseGutter * 2;
      }
      p {
        font-size: $sText;
        text-align: justify;
        margin: 0 $baseGutter * 2;
      }
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: absolute;
        right: -20px;
        top: -20px;
        box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.1);
        border: solid 0.5px $accentColor;
      }
    }
  }
}
