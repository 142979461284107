@import "./../utils/values.scss";
@import "./../utils/mixins.scss";
section.hero {
  background: $heroBgColor;
  @include d-flex-row;
  justify-content: center;
  align-items: center;
  padding: ($baseGutter * 16) ($baseGutter * 2);
  position: relative;
  gap: $baseGutter * 8;
  div.intro {
    @include d-flex-column;
    justify-content: center;
    max-width: 40vw;
    p {
      margin-top: $baseGutter;
      font-size: $lText;
      letter-spacing: 1px;
      text-align: justify;
      &:nth-of-type(2) {
        margin-top: $baseGutter * 3;
      }
    }
  }
  div.profile-pic {
    @include d-flex-column;
    img {
      max-width: 47vh;
      min-width: 260px;
    }
  }
  svg.curve {
    position: absolute;
    top: 99.9%;
    left: 0;
    z-index: 9;
    height: 40px;
    transform: rotate(180deg);
    path {
      fill: $heroBgColor;
    }
  }
}
@media only screen and (max-width: $mediumScreenBreakpoint) {
  section.hero {
    flex-direction: column-reverse;
    padding: ($baseGutter * 8) ($baseGutter);
    gap: 0;
    div.intro {
      align-items: center;
      max-width: 80%;
      text-align: center;
    }
    div.profile-pic {
      img {
        max-width: 50vw;
      }
    }
    svg.curve {
      top: 99%;
    }
  }
}
@media only screen and (max-width: $smallScreenBreakpoint) {
  section.hero {
    div.intro {
      p {
        font-size: $mText;
      }
    }
    div.profile-pic {
      img {
        max-width: 65vw;
      }
    }
  }
}
