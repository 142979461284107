@import url("https://fonts.googleapis.com/css2?family=Italianno&family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./../utils/values.scss";
@import "./../utils/mixins.scss";
html,
body,
section,
div,
p,
h1,
h2,
h3,
h4 {
  @include no-margin-padding;
}
body {
  font-family: "Jost", sans-serif;
  color: $textParagraphColor;
}
h1.title {
  @include title-text;
}
h1,
h2,
h3,
h4 {
  color: $textTitleColor;
  width: fit-content;
}
@media only screen and (max-width: $smallScreenBreakpoint) {
  h1.title {
    font-size: $xlText;
  }
}
