@import "./../utils/values.scss";
@import "./../utils/mixins.scss";
$textShadow: #ffe601;
section.experience {
  background: $participationBgColor;
  padding: ($baseGutter * 10) ($baseGutter * 4) ($baseGutter * 16);
  position: relative;
  align-items: center;
  @include d-flex-column;
  gap: $baseGutter * 6;
  div.content {
    @include d-flex-row;
    gap: $baseGutter * 4;
    position: relative;
    z-index: 9;
    hr {
      border: solid 0.5px silver;
    }
    div.tab {
      @include d-flex-column;
      gap: $baseGutter;
      div.tab-title {
        @include d-flex-row;
        align-items: center;
        gap: $baseGutter;
        h2 {
          letter-spacing: 1px;
          font-weight: 600;
          text-shadow: 0 0 2px $textShadow;
        }
        svg {
          height: 20px;
          filter: drop-shadow(0 0 2px $textShadow);
        }
      }
      div.tab-content {
        @include d-flex-column;
        gap: $baseGutter;
        div.item {
          align-items: center;
          text-align: center;
          h3 {
            font-weight: 500;
            font-size: $mText;
          }
          ul {
            list-style: none;
            @include no-margin-padding;
            li {
              @include d-flex-row;
              align-items: center;
              svg {
                height: $sText;
                width: $xlText;
              }
              span {
                font-size: $sText;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: $baseGutter * 2;
    top: $baseGutter * 6;
    right: $baseGutter * 2;
    border-left: solid 2px rgba(47, 79, 79, 0.5);
    border-bottom: solid 2px rgba(47, 79, 79, 0.5);
    border-top: solid 2px rgba(47, 79, 79, 0.5);
    box-sizing: border-box;
  }
  div.alt-icon {
    position: absolute;
    top: $baseGutter * 7;
    right: $baseGutter * 2;
    background: rgba(47, 79, 79, 0.8);
    backdrop-filter: blur(4px);
    height: 60px;
    width: 60px;
    z-index: 9;
    @include d-flex-column;
    justify-content: center;
    align-items: center;
    border-radius: 25%;
    padding: $baseGutter;
    box-shadow: inset 8px 8px 8px rgba(0, 0, 0, 0.5),
      inset -8px -8px 8px rgba(255, 255, 255, 0.5);
    svg {
      margin-bottom: 4px;
      color: $participationBgColor;
      height: 46px;
      width: 46px;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.75));
    }
    &:before {
      content: " ";
      position: absolute;
      top: -$baseGutter;
      left: calc(50% + 2px);
      transform: translateY(-25%);
      height: $baseGutter;
      width: 50%;
      background: $participationBgColor;
    }
    &:after {
      content: " ";
      position: absolute;
      left: 50%;
      bottom: 100%;
      transform: translateX(50%);
      top: -$baseGutter;
      border: 1px solid rgba(47, 79, 79, 0.5);
    }
  }
}
@media only screen and (max-width: ($mediumScreenBreakpoint + 120px)) {
  section.experience {
    padding-left: $baseGutter * 2;
    padding-right: $baseGutter * 2;
    div.content {
      flex-direction: column;
      hr {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: ($smallScreenBreakpoint + 60px)) {
  section.experience {
    div.content {
      div.tab {
        div.tab-title {
          h2 {
            font-weight: 500;
            font-size: $sText;
          }
          svg {
            height: 16px;
          }
        }
        div.tab-content {
          div.item {
            h3 {
              font-size: $xsText;
            }
            ul li {
              align-items: start;
              svg {
                height: 12px;
                width: 24px;
                padding-top: 4px;
              }
              span {
                font-size: $xsText;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
